export default function Footer() {
    return (
        <div className="overflow-hidden border-t bg-base-100 border-base-200 mt-20">
            <div className="container mx-auto">
                <footer className="footer p-10  text-base-content">
                    <aside>
                        <img src="/banner.png" className="w-48" alt="logo" />
                        <p className=" text-sm text-base-content">Dijital Çağda Söz Sahibi Olun</p>
                        <div className="grid grid-flow-col gap-4 place-content-center">
                            <a className="btn btn-ghost btn-sm btn-square hover:bg-[#0077d3] hover:text-white duration-300">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a className="btn btn-ghost btn-sm btn-square hover:bg-[#0077d3] hover:text-white duration-300">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a className="btn btn-ghost btn-sm btn-square hover:bg-[#0077d3] hover:text-white duration-300">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a className="btn btn-ghost btn-sm btn-square hover:bg-[#0077d3] hover:text-white duration-300">
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </div>
                    </aside>
                    <nav>
                        <header className="footer-title">Bağlantılar</header>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/">Ana Sayfa</a>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/projects">Projeler</a>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/courses">Kurslarımız</a>
                    </nav>
                    <nav>
                        <header className="footer-title">Bize Ulaşın</header>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/about">Hakkımızda</a>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/team">Ekibimiz</a>
                        <a className="no-underline hover:text-[#0077d3] duration-300" href="/contact">İletişim</a>
                    </nav>
                </footer>
                <p className="text-center text-sm text-base-content py-4">© {new Date().getFullYear()} metaakdeniz.com. Tüm hakları saklıdır.</p>
            </div>
        </div>
    )
}