export default function Navbar() {
  return (<>
    <div className="navbar bg-base-100">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
          </label>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
            <li><a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700" href="/">Ana Sayfa</a></li>
            <li>
              <a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700">Biz kimiz?</a>
              <ul className="p-2">
                <li><a href="/about">Hakkımızda</a></li>
                <li><a href="/team">Ekibimiz</a></li>
                <li><a href="/contact">İletişim</a></li>
              </ul>
            </li>
            <li><a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700" href="/projects">Projelerimiz</a></li>
            <li><a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700" href="/courses">Eğitimlerimiz</a></li>
          </ul>
        </div>
        <a href="/">
          <img src="/banner.png" className="w-[300px]" alt="" srcset="" />
        </a>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li><a href="/" className="hover:bg-[#1b1a39]/80 hover:text-white duration-700">Ana Sayfa</a></li>
          <li tabIndex={0}>
            <details>
              <summary className="hover:bg-[#1b1a39]/80 hover:text-white duration-700">Biz kimiz?</summary>
              <ul className="p-2 z-50">
                <li><a href="/about">Hakkımızda</a></li>
                <li><a href="/team">Ekibimiz</a></li>
                <li><a href="/contact">İletişim</a></li>
              </ul>
            </details>
          </li>
          <li><a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700" href="/projects">Projelerimiz</a></li>
          <li><a className="hover:bg-[#1b1a39]/80 hover:text-white duration-700" href="/courses">Eğitimlerimiz</a></li>
        </ul>
      </div>
      <div className="navbar-end">

      </div>
    </div>
  </>)
}