import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import Navbar from './components/Header/Navbar.jsx'
import Footer from './components/Header/Footer.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Navbar />
    <div className="md:px-16" >
    <App />
    </div>
    <Footer />
  </React.StrictMode>,
)
