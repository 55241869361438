import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useState } from "react";

const IndexPage = lazy(() => import("./Pages/Index/Index.jsx"));
const AboutPage = lazy(() => import("./Pages/About/Index.jsx"));
const TeamPage = lazy(() => import("./Pages/Team/Index.jsx"));
const ProjectsPage = lazy(() => import("./Pages/Projects/Index.jsx"));
const CoursesPage = lazy(() => import("./Pages/Courses/Index.jsx"));
const ContactPage = lazy(() => import("./Pages/Contact/Index.jsx"));

export default function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {loading && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-60 z-50 flex items-center justify-center overflow-hidden">
          <div className="flex items-center justify-center flex-col">
            <i className="fas fa-spinner fa-spin text-4xl"></i>
          </div>
        </div>

      )}
      <Router>
        <Routes>
          <Route
            path="/"
            element={<IndexPage />}
          />
          <Route
            path="/about"
            element={<AboutPage />}
          />
          <Route
            path="/team"
            element={<TeamPage />}
          />
          <Route
            path="/projects"
            element={<ProjectsPage />}
          />
          <Route
            path="/courses"
            element={<CoursesPage />}
          />
          <Route
            path="/contact"
            element={<ContactPage />}
          />
        </Routes>
      </Router>
    </React.Fragment>
  );
};